import React, { Component } from 'react';
import '../../styles/footer.css'
const date = new Date();
let year = date.getFullYear();

class Footer extends Component {

    render() {
        return (
            <footer className='footer'>
                <hr className='footerHr' />
                <p>{year} What The Tech, LLC&ensp;|&ensp;All rights reserved.</p>
            </footer>
        )
    }
}

export default Footer;