import React, { Component } from 'react';
import { Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';
import { capitalizeFirstLetter, removeLastLetter } from '../../js/commonFunctions';

const TopQuizzes = () => {

    const topQuizSelected = (quizName) => {
        ReactGA.event({
            category: 'Quiz Buttons',
            action: quizName+' quiz selected',
            label: 'Top Quizzes Button'
        });
    }

        return (
            <>
                <Col sm='12' md='6' className='my-auto'>
                    <Link to={'/quizzes/laptops'} onClick={()=> topQuizSelected('laptops')}>
                        <div className="primaryQuizBlock1">
                                <img src={require('../../images/homePage/laptop.svg')} className='primaryQuizBlockImage mx-auto' alt='Laptop finder quiz'/>
                                <div className='primaryQuizBlockTextContainer'>
                                    <div className='primaryQuizBlockText'>laptops</div>
                                </div>
                        </div>
                    </Link>
                </Col>
                <Col sm='12' md='6' className='my-auto' >
                    <Link to={'/quizzes/tablets'} onClick={()=> topQuizSelected('tablets')}>
                        <div className="primaryQuizBlock1 primaryQuizBlock2">
                            <img src={require('../../images/homePage/tablet.svg')} className='primaryQuizBlockImage mx-auto' alt='Tablet finder quiz'/>
                            <div className='primaryQuizBlockTextContainer primaryQuizBlockTextContainer2'>
                                <div className='primaryQuizBlockText'>tablets</div>
                            </div>
                        </div>
                    </Link>
                </Col>
                
            </>
        )
}

export default TopQuizzes;